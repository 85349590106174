/**
 * List contents.
 */
import { apiGet } from '@/util/api';
import Icon from '@/icons/Icon.vue';

export default {
  name: 'TaggingSummary',
  components: {
    Icon,
  },
  data() {
    return {
      tags: [],
      contents: [],
      countries: [{ text: this.$t('tags_list_all_countries'), value: null }],
      categories: {},
      categoriesOptions: [],
      category: null,
      country: null,
      showUnpublished: true,
      loading: true,
      tagListLoading: true,
      tagsTablePerPage: 16,
      tagsTableCurrentPage: 1,
      tagsTableFields: [
        {
          key: 'name',
          label: this.$t('tags_list_tag_name'),
        },
        {
          key: 'count',
          label: this.$t('tags_list_tag_count'),
        },
      ],
      deleteContent: {},
    };
  },
  async created() {
    try {
      this.tags = await apiGet('/tag?orderBySort=name&orderByOrder=ASC');

      this.tags = this.tags.filter(t => {
        if (t.category === 'COUNTRY') {
          this.countries.push({ text: t.name, value: t.id });
          return false;
        } else if (t.category === 'GENRE') {
          return true;
        } else if (t.category === 'CATEGORY') {
          this.categories[t.id] = t.name;
          this.categoriesOptions.push({ text: t.name, value: t.id });
          if (t.name === 'Beautiful') this.category = t.id;
        }
        return false;
      });

      this.countries = this.countries.sort((a, b) => this.sort(a, b, 'text'));

      this.contents = await apiGet(
        '/content?thumbnails=false&orderBySort=name&orderByOrder=ASC&unpublished=true&withTags=true',
      );
    } catch (error) {
      this.$bvToast.toast(this.$t('clents_error_get_contents'), {
        title: this.$t('common_error_title'),
        variant: 'danger',
      });
    } finally {
      this.loading = false;
    }
  },
  computed: {
    tagList() {
      this.tagListLoading = true;
      if (!this.contents || this.contents.length === 0) return [];
      try {
        let tags = this.tags;

        tags = tags.filter(t => t.parentTag === this.category);
        tags.forEach(t => (t.count = 0));
        /*
          ++ count for each tag only if there is no country selected or
          the content has the selected country in its tags
        */
        this.contents.forEach(c => {
          if (
            (this.showUnpublished || c.published) &&
            (!this.country || c.tags.some(t => t.id === this.country))
          ) {
            c.tags.forEach(t => {
              const x = tags.findIndex(t2 => t.name === t2.name);
              if (x > -1) tags[x].count++;
            });
          }
        });

        return tags;
      } finally {
        this.tagListLoading = false;
      }
    },
  },
  methods: {
    /**
     * Create and init download of tag metrics CSV.
     */
    async generateCSV() {
      this.loading = true;

      const countriesCounts = {};
      const countriesLabels = {};
      const countriesOrder = [];
      this.countries.forEach(c => {
        if (c.value) {
          countriesOrder.push(c.value);
          countriesCounts[c.value] = 0;
          countriesLabels[c.value] = c.text;
        }
      });

      const genresLabels = {};
      this.tags
        .filter(t => t.category === 'GENRE')
        .forEach(c => {
          genresLabels[c.id] = c.name;
        });

      const tags = {};
      this.tags
        .filter(t => t.category === 'GENRE')
        .forEach(t => {
          if (t.category === 'GENRE') {
            tags[t.id] = {
              genre: t.name,
              category: this.categories[t.parentTag],
              total: 0,
              countries: { ...countriesCounts },
            };
          }
        });

      this.contents.forEach(c => {
        /*
          Every genre in `contentGenres` should have +1 added to any country in its
          `countries` attribute that appears in `contentCountries` (and to `total`).
        */
        const contentGenres = c.tags
          .filter(t => t.category === 'GENRE')
          .map(t => t.id);
        const contentCountries = c.tags
          .filter(t => t.category === 'COUNTRY')
          .map(t => t.id);

        contentGenres.forEach(g => {
          tags[g].total++;
          contentCountries.forEach(c => {
            tags[g].countries[c]++;
          });
        });
      });

      let output = 'GENRE,CATEGORY,TOTAL,';
      countriesOrder.forEach(o => (output += countriesLabels[o] + ','));
      output = output.slice(0, -1);
      output += '\r\n';

      let F = [],
        U = [],
        B = [],
        I = [];

      Object.values(tags).forEach(t => {
        if (t.category === 'Funny') F.push(t);
        else if (t.category === 'Useful') U.push(t);
        else if (t.category === 'Beautiful') B.push(t);
        else if (t.category === 'Inspiring') I.push(t);
      });

      F = F.sort((a, b) => this.sort(a, b, 'genre'));
      U = U.sort((a, b) => this.sort(a, b, 'genre'));
      B = B.sort((a, b) => this.sort(a, b, 'genre'));
      I = I.sort((a, b) => this.sort(a, b, 'genre'));

      [...F, ...U, ...B, ...I].forEach(t => {
        output += `${t.genre},${t.category},${t.total},`;
        countriesOrder.forEach(o => (output += t.countries[o] + ','));
        output = output.slice(0, -1);
        output += '\r\n';
      });

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(output),
      );
      downloadLink.setAttribute('download', `TagMetrics${Date.now()}.csv`);
      downloadLink.click();
      downloadLink.remove();

      this.loading = false;
    },
    sort(a, b, i) {
      if (a[i] < b[i]) {
        return -1;
      }
      if (a[i] > b[i]) {
        return 1;
      }

      return 0;
    },
  },
};
